<template>
  <!-- 天创的接口数据 -->
  <div class="success" ref="imageTofile">
    <div class="bannerBox">
      <div class="bannerBox-title">报告解读 不懂就问</div>
      <img src="../assets/grbg/banner.png" mode="widthFix" />

      <div class="dw_img" v-if="cid != 928">
        <img :src="reportImg" alt="" />
      </div>
    </div>
    <!-- 分析指数 -->
    <div class="" id="fxzsBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>分析指数</span>
      </div>
      <div class="content">
        <span>
          分析指数是根据网络行为大数据出具的分析评估参考分数。该指数仅对本报告有效，不代表对报告查询人的综合定性评价。
        </span>
        <div class="main" id="main">
          <!-- 表格形式 -->
          <Echarts :echartsValue="echartsValue"></Echarts>
        </div>
      </div>
    </div>

    <!-- 报告概况 -->
    <div class="" id="bggkBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>报告概况</span>
      </div>
      <div class="content">
        <div class="generatedTime">
          <span>报告生成时间: {{ createTime }}</span>
          <span>报告失效时间: {{ failureTime }}</span>
        </div>
        <div class="involve">
          <div class="item">
            <img src="../assets/grbg/bg.png" mode="widthFix" />
            <span>报告一共涉及7项内容</span>
          </div>
          <div class="item">
            <img src="../assets/grbg/mz.png" mode="widthFix" />
            <span>报告中有一项内容需要关注</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 报告基本信息 -->
    <div class="" id="jbxxBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>基本信息</span>
      </div>
      <div class="content">
        <div class="userdata">
          <div class="item">
            <span>姓名</span>
            <span>性别</span>
            <span>年龄</span>
            <span>星座</span>
          </div>
          <div class="item">
            <span>{{ userInfo.username }}</span>
            <span>{{ userInfo.sex == 0 ? '女' : '男' }}</span>
            <span>{{ userInfo.age }}</span>
            <span>{{ userInfo.constellation }}</span>
          </div>
        </div>
        <div class="info">
          <span>身份证</span>
          <span>{{ userInfo.idCard }}</span>
        </div>
        <div class="info">
          <span>身份证首次发放地</span>
          <span>{{ userInfo.idCardFirstAddr }}</span>
        </div>
        <div class="info">
          <span>手机号</span>
          <span>{{ userInfo.mobile }}</span>
        </div>
        <div class="info">
          <span>手机号所属地</span>
          <span>{{ userInfo.phoneHomeAddress }}</span>
        </div>
        <div class="info">
          <span>身份证检查结果</span>
          <span>{{ userInfo.idCardCheckResult }}</span>
        </div>
        <div class="info">
          <span>手机号检测结果</span>
          <span> {{ userInfo.phoneCheckResult || '暂无' }} </span>
        </div>
      </div>
      <div class="unfold">
        <div class="unfold-tips">
          <div class="unfold-tips-one">
            <img src="../assets/grbg/tips.png" mode="widthFix" />
            <span>小贴士</span>
          </div>
          <div class="unfold-tips-two">
            <img
              v-if="!tipsShow1"
              @click="tipsShow1 = !tipsShow1"
              src="../assets/grbg/xia.png"
              mode="widthFix"
            />
            <img
              v-else
              @click="tipsShow1 = !tipsShow1"
              src="../assets/grbg/shang.png"
              mode="widthFix"
            />
          </div>
        </div>

        <div :class="tipsShow1 ? 'unfold-box unfold-boxActive' : 'unfold-box'">
          如查询的姓名/身份证与运营商提供的不一致，可能会存在报告内容不匹配的情况。
        </div>
        <img class="dwbs1" src="../assets/grbg/bs.png" mode="widthFix" />
        <img class="dwbs2" src="../assets/grbg/bs.png" mode="widthFix" />
      </div>
    </div>

    <!-- 高风险排查 -->
    <div class="" id="gfxBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>高风险排查</span>
      </div>
      <div class="content">
        <div class="gfxpcItem">
          <span>金融类</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>侵犯他人财产</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>侵犯公民人身民主权利</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>涉赌</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>妨害国家、公共安全、涉危险物品</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>妨害社会管理秩序</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>其他前科</span>
          <span>未命中</span>
        </div>

        <div class="gfxpcItem">
          <span>涉毒</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>吸毒</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>在逃</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>洗案</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>重点人员</span>
          <span>未命中</span>
        </div>
        <div class="gfxpcItem">
          <span>涉黑涉恐</span>
          <span>未命中</span>
        </div>
      </div>
    </div>

    <!-- 银行贷款类申请记录 -->
    <div class="" id="yhdkBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>银行贷款类申请记录</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">申请汇总</span>
        </div>

        <div class="titlebox">
          <!-- 柱状echarts -->
          <columnarEcharts :columnar="columnar"></columnarEcharts>
        </div>
        <!-- 机构类别申请记录 -->

        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">机构类别申请记录</span>
        </div>
        <div class="titlebox">
          <div class="mytabs">
            <div
              class="mytabs-item"
              v-for="item in mytabs"
              :key="item.id"
              @click="changeTbjgid(item.id)"
            >
              <span
                :class="tbjgid == item.id ? 'itemTop active1' : 'itemTop'"
                >{{ item.lable }}</span
              >
              <span
                :class="tbjgid == item.id ? 'itemTop active2' : 'itemTop'"
              ></span>
            </div>
          </div>
          <div class="titlebox-content">
            <span>时段</span>
            <span>申请次数</span>
            <span>申请机构数</span>
          </div>
          <div class="titlebox-content titlebox-content2">
            <span>银行产品</span>
            <span v-if="traditionalBankNumList">{{
              traditionalBankNumList[tbjgid]
            }}</span>
            <span v-if="traditionalBankOrganizationNumList">{{
              traditionalBankOrganizationNumList[tbjgid]
            }}</span>
          </div>
          <div class="titlebox-content titlebox-content3">
            <span>非银行产品</span>
            <span v-if="netBankNumList">{{ netBankNumList[tbjgid] }}</span>
            <span v-if="netBankOrganizationNumList">{{
              netBankOrganizationNumList[tbjgid]
            }}</span>
          </div>
        </div>
      </div>
      <div class="unfold">
        <div class="unfold-tips">
          <div class="unfold-tips-one">
            <img src="../assets/grbg/tips.png" mode="widthFix" />
            <span>小贴士</span>
          </div>
          <div class="unfold-tips-two">
            <img
              v-if="!tipsShow2"
              @click="tipsShow2 = !tipsShow2"
              src="../assets/grbg/xia.png"
              mode="widthFix"
            />
            <img
              v-else
              @click="tipsShow2 = !tipsShow2"
              src="../assets/grbg/shang.png"
              mode="widthFix"
            />
          </div>
        </div>
        <div :class="tipsShow2 ? 'unfold-box unfold-boxActive' : 'unfold-box'">
          【传统银行】：依托线下门店提供活期存款和商业贷款的金融机构。例如：中国银行、工商银行、农业银行等；<br />
          【网络零售银行】：没有线下网点，不做现金业务、没有分行、没有柜台，轻资产、平台化，以服务小微企业为主的线上银行。例如：新网银行、微众银行等。
        </div>
        <img class="dwbs1" src="../assets/grbg/bs.png" mode="widthFix" />
        <img class="dwbs2" src="../assets/grbg/bs.png" mode="widthFix" />
      </div>
    </div>

    <!-- 借贷记录 -->
    <div class="" id="jdjlBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>借贷记录</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">贷款机构</span>
        </div>

        <div class="sjbq">
          <span>借贷机构数</span>
          <span>{{ loanOverallSituation.xypCpl0001 | xypCpl0001 }}</span>
        </div>

        <div class="sjbq active">
          <span>消费金融类机构数(有场景的、分期)</span>
          <span>{{ loanOverallSituation.xypCpl0007 | xypCpl0007 }}</span>
        </div>
        <div class="sjbq">
          <span>网络贷款类机构数(现金贷)</span>
          <span>{{ loanOverallSituation.xypCpl0008 | xypCpl0008 }}</span>
        </div>

        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">贷款整体情况</span>
        </div>
        <div class="sjbq">
          <span>贷款已结清机构数</span>
          <span>{{ loanOverallSituation.xypCpl0002 | xypCpl0002 }}</span>
        </div>
        <div class="sjbq active">
          <span>信用贷款时长(天)</span>
          <span>{{ loanOverallSituation.xypCpl0045 | xypCpl0045 }}</span>
        </div>
        <div class="sjbq Antline">
          <span>最近一次交易距离当前时间(天)</span>
          <span>{{ loanOverallSituation.xypCpl0046 | xypCpl0046 }}</span>
        </div>
        <div class="sjbq">
          <span>累计交易金额</span>
          <span>{{ loanOverallSituation.xypT01aczzzz | xypT01aczzzz }}</span>
        </div>
        <div class="sjbq active">
          <span>因交易潜力不足导致失败的交易金额(最小值)</span>
          <span>{{ loanOverallSituation.xypT01abzzza | xypT01abzzza }}</span>
        </div>
        <div class="sjbq">
          <span>历史贷款机构成功还款笔数</span>
          <span>{{ loanOverallSituation.xypCpl0014 | xypCpl0014 }}</span>
        </div>
        <div class="sjbq active">
          <span>历史贷款机构交易失败笔数</span>
          <span>{{ loanOverallSituation.xypCpl0015 | xypCpl0015 }}</span>
        </div>
        <div class="titlebg" style="margin-top: 30px">
          <img :src="littleTitleImg" mode="" />
          <span class="title">贷款逾期</span>
        </div>
        <div class="sjbq active">
          <span>当前是否存在逾期未结算</span>
          <span>{{
            loanOverallSituation.xypCpl0071 == 0 ? '未逾期' : '已逾期'
          }}</span>
        </div>
        <div class="sjbq active">
          <span>当前逾期机构数</span>
          <span>{{ loanOverallSituation.xypCpl0071 | xypCpl0071 }}</span>
        </div>
        <div class="sjbq active">
          <span>当前逾期金额（元）</span>
          <span>{{ loanOverallSituation.xypCpl0072 | xypCpl0072 }}</span>
        </div>

        <div class="titlebg" style="margin-top: 30px">
          <img :src="littleTitleImg" mode="" />
          <span class="title">贷款分周期汇总</span>
        </div>
        <div class="dkTabsBox">
          <div class="dkTabs">
            <div class="dkTabsItem active">
              <span>时间</span>
              <span>贷款机构数</span>
              <span>还款成功笔数</span>
              <span>还款成功总金额(元)</span>
              <span>交易失败笔数</span>
              <span>交易失败总金额(元)</span>
            </div>
            <div class="dkTabsItem">
              <span>近一天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '1', loanOrganizationList[0])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '1', repaymentSuccessNumList[0])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '1', repaymentSuccessMoneyList[0])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '1', transactionFailNumList[0])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '1', transactionFailMoneyList[0])
              }}</span>
            </div>
            <div class="dkTabsItem active">
              <span>近7天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '7', loanOrganizationList[1])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '7', repaymentSuccessNumList[1])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '7', repaymentSuccessMoneyList[1])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '7', transactionFailNumList[1])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '7', transactionFailMoneyList[1])
              }}</span>
            </div>
            <div class="dkTabsItem">
              <span>近14天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '14', loanOrganizationList[2])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '14', repaymentSuccessNumList[2])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '14', repaymentSuccessMoneyList[2])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '14', transactionFailNumList[2])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '14', transactionFailMoneyList[2])
              }}</span>
            </div>
            <div class="dkTabsItem active">
              <span>近21天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '21', loanOrganizationList[3])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '21', repaymentSuccessNumList[3])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '21', repaymentSuccessMoneyList[3])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '21', transactionFailNumList[3])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '21', transactionFailMoneyList[3])
              }}</span>
            </div>
            <div class="dkTabsItem">
              <span>近30天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '30', loanOrganizationList[4])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '30', repaymentSuccessNumList[4])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '30', repaymentSuccessMoneyList[4])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '30', transactionFailNumList[4])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '30', transactionFailMoneyList[4])
              }}</span>
            </div>
            <div class="dkTabsItem active">
              <span>近90天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '90', loanOrganizationList[5])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '90', repaymentSuccessNumList[5])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj('还款成功总金额', '90', repaymentSuccessMoneyList[5])
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '90', transactionFailNumList[5])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '90', transactionFailMoneyList[5])
              }}</span>
            </div>
            <div class="dkTabsItem">
              <span>近180天</span>
              <span v-if="loanOrganizationList">{{
                handelDkSj('贷款机构数', '180', loanOrganizationList[6])
              }}</span>
              <span v-if="repaymentSuccessNumList">{{
                handelDkSj('还款成功笔数', '180', repaymentSuccessNumList[6])
              }}</span>
              <span v-if="repaymentSuccessMoneyList">{{
                handelDkSj(
                  '还款成功总金额',
                  '180',
                  repaymentSuccessMoneyList[6]
                )
              }}</span>
              <span v-if="transactionFailNumList">{{
                handelDkSj('交易失败笔数', '180', transactionFailNumList[6])
              }}</span>
              <span v-if="transactionFailMoneyList">{{
                handelDkSj('交易失败总金额', '180', transactionFailMoneyList[6])
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 关联人异常 -->
    <div class="" id="glryBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>关联人异常检测</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">关联人银行失联欺诈行为检测</span>
        </div>
        <div class="sjbq">
          <span>是否有银行失联欺诈行为</span>
          <span>否</span>
        </div>
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">关联人银行失联欺诈行为</span>
        </div>
        <div class="sjbq">
          <span>是否有网络小贷欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq Antline">
          <span>是否有消费金融公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否有融资租赁公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否有汽车金融公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否有其他金融公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">关联人异常行为检测</span>
        </div>

        <div class="sjbq">
          <span>是否有银行失联欺诈行为</span>
          <span>否</span>
        </div>

        <div class="shjbTbas">
          <div :class="glrID == 1 ? 'aaa abActive' : 'aaa'" @click="glrID = 1">
            普通异常
          </div>
          <div :class="glrID == 2 ? 'bbb abActive' : 'bbb'" @click="glrID = 2">
            严重异常
          </div>
        </div>
        <div class="sjbq active">
          <span>是否有网络小贷欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq Antline">
          <span>是否有地方小贷欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否有消费金融公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否有融资租赁公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否有汽车金融公司欺诈失联</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否有其他金融公司欺诈失联</span>
          <span>否</span>
        </div>
      </div>
    </div>

    <!-- 失信案件扫描 -->
    <div class="" id="sxajBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>失信案件扫描</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">失信案件</span>
        </div>
        <div :class="shixinShow ? 'falsekong' : 'itemActive'" v-if="shixin">
          <div v-for="(item, index) in shixin" :key="index">
            <div class="falsekong-item1">
              <span>案件编号：{{ item.caseNo || '法院信息还未同步更新' }}</span>
              <div v-if="index == 0">
                <img
                  v-if="shixinShow"
                  src="../assets/grbg/shang.png"
                  mode=""
                  @click="shixinShow = !shixinShow"
                />
                <img
                  v-else
                  src="../assets/grbg/xia.png"
                  mode=""
                  @click="shixinShow = !shixinShow"
                />
              </div>
            </div>
            <div class="falsekong-item2">
              <span>被执行人姓名：{{ item.pname }}</span>
            </div>
            <div class="falsekong-item2">
              <span>性别：{{ item.sex }}</span>
            </div>
            <div class="falsekong-item2">
              <span>法院名称：{{ item.court }}</span>
            </div>
            <div class="falsekong-item2">
              <span>立案时间：{{ item.sortTimeString }}</span>
            </div>
            <div class="falsekong-item2">
              <span
                >被执行人的履行情况：{{
                  item.lxqk || '法院信息还未同步更新'
                }}</span
              >
            </div>
            <div class="falsekong-item2">
              <span
                >失信被执行人行为具体情形：{{
                  item.itqx || '法院信息还未同步更新'
                }}</span
              >
            </div>
            <div v-if="index < shixin.length - 1" class="falsekong-item2">
              <span>-------------------------</span>
            </div>
          </div>
        </div>
        <div class="kong" v-else>
          <img
            src="../assets/grbg/pic_good_home-8b326ffe.png"
            mode="widthFix"
          />
          <div>暂无其他案件</div>
        </div>
      </div>
    </div>

    <!-- 执行案件扫描 -->
    <div class="" id="axajBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>执行案件扫描</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">被执行案件扫描</span>
        </div>
        <div :class="zxggShow ? 'falsekong' : 'itemActive'" v-if="zxgg">
          <div v-for="(item, index) in zxgg" :key="index">
            <div class="falsekong-item1">
              <span>案件编号:{{ item.caseNo || '法院信息还未同步更新' }}</span>
              <div v-if="index == 0">
                <img
                  v-if="zxggShow"
                  src="../assets/grbg/shang.png"
                  mode=""
                  @click="zxggShow = !zxggShow"
                />
                <img
                  v-else
                  src="../assets/grbg/xia.png"
                  mode=""
                  @click="zxggShow = !zxggShow"
                />
              </div>
            </div>
            <div class="falsekong-item2">
              <span>当事人: {{ item.pname }}</span>
            </div>
            <div class="falsekong-item2">
              <span>执行法院名称：{{ item.court }}</span>
            </div>
            <div class="falsekong-item2">
              <span>立案时间：{{ item.sortTimeString }}</span>
            </div>
            <div class="falsekong-item2">
              <span>具体内容：{{ item.body || '法院信息还未同步更新' }}</span>
            </div>
            <!-- <div class="falsekong-item2">
              <span>身份证: {{ item.idcardNo }}</span>
            </div>
            <div class="falsekong-item2">
              <span>执行标的:{{ item.execMoney }}</span>
            </div>
            <div class="falsekong-item2">
              <span>案件状态：{{ item.caseState }}</span>
            </div> -->
            <div v-if="index < zxgg.length - 1" class="falsekong-item2">
              <span>-------------------------</span>
            </div>
          </div>
        </div>
        <div class="kong" v-else>
          <img
            src="../assets/grbg/pic_good_home-8b326ffe.png"
            mode="widthFix"
          />
          <div>暂无其他案件</div>
        </div>
      </div>
    </div>

    <!-- 限制高消费信息 -->
    <div class="" id="xzgxBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>限制高消费信息</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">限高执行</span>
        </div>
        <div :class="bgtShow ? 'falsekong' : 'itemActive'" v-if="bgt">
          <div v-for="(item, index) in bgt" :key="index">
            <div class="falsekong-item1">
              <span>案件编号：{{ item.caseNo || '法院信息还未同步更新' }}</span>
              <div v-if="index == 0">
                <img
                  v-if="bgtShow"
                  src="../assets/grbg/shang.png"
                  mode=""
                  @click="bgtShow = !bgtShow"
                />
                <img
                  v-else
                  src="../assets/grbg/xia.png"
                  mode=""
                  @click="bgtShow = !bgtShow"
                />
              </div>
            </div>
            <div class="falsekong-item2">
              <span>当事人: {{ item.pname }}</span>
            </div>
            <div class="falsekong-item2">
              <span>执行法院名称：{{ item.court }}</span>
            </div>
            <div class="falsekong-item2">
              <span>立案时间：{{ item.sortTimeString }}</span>
            </div>
            <div class="falsekong-item2">
              <span>具体内容：{{ item.body || '法院信息还未同步更新' }}</span>
            </div>
            <div class="falsekong-item2">
              <span
                >未执行金融：{{
                  item.unnexeMoney || '法院信息还未同步更新'
                }}</span
              >
            </div>
            <!-- <div class="falsekong-item2">
              <span>内容: {{ item.body }}</span>
            </div>
            <div class="falsekong-item2">
              <span>匹配度: {{ item.matchRatio * 100 }}%</span>
            </div> -->
            <div v-if="index < bgt.length - 1" class="falsekong-item2">
              <span>-------------------------</span>
            </div>
          </div>
        </div>
        <div class="kong" v-else>
          <img
            src="../assets/grbg/pic_good_home-8b326ffe.png"
            mode="widthFix"
          />
          <div>暂无其他案件</div>
        </div>
      </div>
    </div>
    <!-- 裁判文书信息 -->
    <div class="" id="xzgxBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>裁判文书信息</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">裁判文书</span>
        </div>
        <div :class="cpwsShow ? 'falsekong' : 'itemActive'" v-if="cpws">
          <div v-for="(item, index) in cpws" :key="index">
            <div class="falsekong-item1">
              <span>案件编号：{{ item.caseNo || '法院信息还未同步更新' }}</span>
              <div v-if="index == 0">
                <img
                  v-if="cpwsShow"
                  src="../assets/grbg/shang.png"
                  mode=""
                  @click="cpwsShow = !cpwsShow"
                />
                <img
                  v-else
                  src="../assets/grbg/xia.png"
                  mode=""
                  @click="cpwsShow = !cpwsShow"
                />
              </div>
            </div>
            <div class="falsekong-item2">
              <span>当事人: {{ item.pname }}</span>
            </div>
            <div class="falsekong-item2">
              <span>法院名称：{{ item.court }}</span>
            </div>
            <div class="falsekong-item2">
              <span>审结时间：{{ item.sortTimeString }}</span>
            </div>
            <div class="falsekong-item2">
              <span
                >判决结果：{{
                  item.judgeResult || '法院信息还未同步更新'
                }}</span
              >
            </div>
            <div v-if="index < cpws.length - 1" class="falsekong-item2">
              <span>-------------------------</span>
            </div>
          </div>
        </div>
        <div class="kong" v-else>
          <img
            src="../assets/grbg/pic_good_home-8b326ffe.png"
            mode="widthFix"
          />
          <div>暂无其他案件</div>
        </div>
      </div>
    </div>
    <!-- 社交标签 -->
    <div class="" id="sjbqBox">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>社交标签</span>
      </div>
      <div class="content">
        <div class="titlebg">
          <img :src="littleTitleImg" mode="" />
          <span class="title">社交标签</span>
        </div>
        <div class="sjbq">
          <span>是否与资料包装中介有关联</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否疑似与异常行业有关联</span>
          <span>否</span>
        </div>
        <div class="sjbq Antline">
          <span>是否疑似虚假资料</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否疑似羊毛党</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否身份信息存疑</span>
          <span>否</span>
        </div>
        <div class="sjbq Antline">
          <span>是否严重异常行为</span>
          <span>否</span>
        </div>

        <div class="sjbq">
          <span>是否存在失信行为</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否存在支付异常行为</span>
          <span>否</span>
        </div>
        <div class="sjbq">
          <span>是否存在其他异常行为</span>
          <span>否</span>
        </div>
        <div class="sjbq active">
          <span>是否上网环境异常</span>
          <span>否</span>
        </div>
      </div>
      <div class="unfold">
        <div class="unfold-tips">
          <div class="unfold-tips-one">
            <img src="../assets/grbg/tips.png" mode="widthFix" />
            <span>小贴士</span>
          </div>
          <div class="unfold-tips-two">
            <img
              v-if="!tipsShow3"
              @click="tipsShow3 = !tipsShow3"
              src="../assets/grbg/xia.png"
              mode="widthFix"
            />
            <img
              v-else
              @click="tipsShow3 = !tipsShow3"
              src="../assets/grbg/shang.png"
              mode="widthFix"
            />
          </div>
        </div>
        <div :class="tipsShow3 ? 'unfold-box unfold-boxActive' : 'unfold-box'">
          是否与黑中介有关联：与从事包装客户资料，伪造客户资料，冒用客户资料，套取机构政策等职业的用户或者机构成员有关联。
          <br />是否疑似与异常行业有关联：互联网行为疑似涉嫌色情、赌博、毒品等不良行为。
          <br />是否疑似虚假资料：在社交平台提供过虚假资料，或者有恶意申请/操作记录，或者个人信息疑似泄漏、冒用、伪造等。
          <br />是否疑似羊毛党：在网贷、电商、O2O等平台有薅羊毛行为的用户。
          <br />是否身份信息存疑：未获取到社交平台中的身份信息或者身份信息（身份证、手机号、姓名）疑似涉嫌伪造。
          <br />是否严重异常行为：疑似有恶意消费的行为。
          <br />是否存在失信行为：客户有失信行为。
          <br />是否存在支付异常行为：支付行为异常包括支付频次、额度、场景等方面有过异常行为。
          <br />是否存在其他异常行为：用户和以下高风险行为可能存在较高关联度：被盗风险较高、社交圈子不固定、地理圈子变化较大。
          <br />是否上网环境异常：用户上网时，有使用虚拟机、代理设备、代理IP、猫池等行为。
        </div>
        <img class="dwbs1" src="../assets/grbg/bs.png" mode="widthFix" />
        <img class="dwbs2" src="../assets/grbg/bs.png" mode="widthFix" />
      </div>
    </div>

    <!-- 分析指数 -->
    <div class="" id="fxzsBox" v-if="cid != 928">
      <div class="imgBox">
        <img :src="headlineImg" mode="widthFix" />
        <span>报告解读</span>
      </div>
      <div class="content">
        <p
          style="
            color: red;
            font-size: 16px;
            text-align: center;
            margin-bottom: 5px;
          "
        >
          保存下面二维码，去微信添加客服<br />VIP1对1耐心解答
        </p>
        <img class="reportImg" :src="reportImg" alt="" />
      </div>
    </div>

    <!-- 结尾 -->
    <div :class="tipsShow2 ? 'floot flootActive' : 'floot'">
      数据报告说明<br />
      本报告的数据由用户本人明确授权后，我们才向相关合法存有用户个人数据的机构去调取本报告相关内容，本平台只做大数据的获取与分析，仅向用户个人展示参考。
    </div>
    <div class="footer">V1.1.3</div>
    <!-- 右侧三个按钮 -->
    <div class="dwthree">
      <successthreeItem
        ref="threeItem"
        :imgurl="reportImg"
        :cid="cid"
      ></successthreeItem>
    </div>

    <!-- 报告截图预览 -->
    <van-popup v-model="show">
      <div class="previewImg">
        <img :src="imgUrl" alt="" @click="downloadImgUrl(imgUrl)" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import { Popup, Toast } from 'vant';
import { reference, server } from '@/api/getUserInfo';
import columnarEcharts from '../components/columnarEcharts.vue';
import Echarts from '../components/Echarts';
import html2canvas from 'html2canvas';
// 右侧三按钮
import successthreeItem from '../components/successthreeItem.vue';
import { handelSj } from '@/utils/LoanCycle.js';
Vue.use(Popup);
export default {
  components: {
    columnarEcharts,
    Echarts,
    successthreeItem,
  },
  data() {
    return {
      // 模块小标题图片（例如：分析指数）
      headlineImg: require('../assets/grbg/fxzs.png'),
      littleTitleImg: require('../assets/grbg/small_tittle03_marry-efe71787.png'),
      Flagshow: true,
      title: 'Hello',
      current: 0,
      // 报告截图预览
      show: false,
      imgUrl: '',
      // 指数
      echartsValue: {
        // 数字刻度
        numberColor: '#c3d6ff',
        // 刻度盘
        scaleColor: '#c3d6ff',
        // 分刻度
        scaleDivisionColor: '#9dddcd',
        // 箭头和指针
        arrowsColor: '#5687fd',
        // 最后分值
        valueNumer: '0',
      },
      // 柱状图
      columnar: {
        xAxis: ['1天', '7天', '14天', '1个月', '3个月', '6个月'],
        xAxisData1: [0, 0, 0, 0, 0, 0],
        xAxisData2: [0, 0, 0, 0, 0, 0],
        xAxisData1Color: '#80acfe',
        xAxisData2Color: '#ffb86d',
      },
      columnar1: {
        xAxis: ['15天', '1个月', '3个月', '6个月', '12个月'],
        xAxisData1: [14, 5, 6, 8],
        xAxisData2: [130, 13, 4, 3, 13],
        xAxisData1Color: '#80acfe',
        xAxisData2Color: '#ffb86d',
      },
      // tabls
      list: [
        { name: '分析指数', id: 'fxzsBox' },
        { name: '报告概况', id: 'bggkBox' },
        { name: '基本信息', id: 'jbxxBox' },
        { name: '银行贷款类申请记录', id: 'yhdkBox' },
        { name: '非银贷款类申请记录', id: 'fyhdBox' },
        { name: '借贷记录', id: 'jdjlBox' },
        { name: '逾期记录', id: 'yqjlBox' },
        { name: '关联人异常检测', id: 'glryBox' },
        { name: '失信案件扫描', id: 'sxajBox' },
        { name: '执行案件扫描', id: 'zxajBox' },
        { name: '限制高消费信息', id: 'xzgxBox' },
        { name: '社交标签', id: 'sjbqBox' },
      ],
      activeStyle: {
        color: '#fff',
        background: '#5787fd',
        padding: '5px 10px',
        borderRadius: '10px',
        fontSize: '28px',
        transform: 'scale(1.05)',
      },
      inactiveStyle: {
        color: '#595959',
        background: '#ededed',
        padding: '5px 10px',
        borderRadius: '10px',
        fontSize: '28px',
      },
      itemactiveCss: {
        padding: '5px 15px',
        borderRadius: '15px',
        fontSize: ' 24px',
        height: '50px',
        lineHeight: ' 50px',
        background: '#5787fd',
        color: '#fff',
      },
      itemActive: '0',
      moreItemShow: false,
      mytabs: [
        { lable: '近7天', id: 0 },
        { lable: '近15天', id: 1 },
        { lable: '近1月', id: 2 },
        { lable: '近3月', id: 3 },
        { lable: '近6月', id: 4 },
        { lable: '近1年', id: 5 },
      ],
      // 特别季候申请记录
      tbjgid: 0,
      Specialid: 0,
      reportOrderId: '',
      // 关联人cative id
      glrID: 1,
      tipsShow1: true,
      tipsShow2: true,
      tipsShow3: true,

      // -------以下是新增的变量属性----------

      // 基本信息 （生成时间  结束时间）
      createTime: '',
      failureTime: '',
      userInfo: {},

      // ------ 机构类别申请记录 -------
      // 传统银行 申请次数
      traditionalBankNumList: null,
      // 传统银行 申请机构数
      traditionalBankOrganizationNumList: null,
      // 网络零售 申请次数
      netBankNumList: null,
      // 网络零售 申请机构数
      netBankOrganizationNumList: null,

      //特殊时段申请机构
      // 周末 申请次数
      specialBankWeekNumList: null,
      // 周末 申请机构数
      specialBankWeekOrganizationNumList: null,
      // 夜间 申请次数
      specialBankNightNumList: null,
      // 夜间 申请机构数
      specialBankNightOrganizationNumList: null,

      // ------ 非银行------------
      notBankId: 0,
      // 小贷 申请次数
      smallNotBankApplyNumList: null,
      // 小贷 申请机构数
      smallNotBankApplyOrganizationNumList: null,
      // 消费分期 申请次数
      consumeNotBankApplyNumList: null,
      // 消费分期 申请机构数
      consumeNotBankApplyOrganizationNumList: null,

      // 现金分期 申请次数
      cashNotBankApplyNumList: null,
      // 现金分期 申请机构数
      cashNotBankApplyOrganizationNumList: null,

      // 代偿分期 申请次数
      compensatoryNotBankApplyNumList: null,
      // 代偿分期 申请机构数
      compensatoryNotBankApplyOrganizationNumList: null,

      // 其他 申请次数
      otherNotBankApplyNumList: null,
      // 其他 申请机构数
      otherNotBankApplyOrganizationNumList: null,

      // speciaID
      speciaID: 0,
      // 特殊时段  周末 申请次数
      specialNotBankWeekNumList: null,
      // 特殊时段  周末 申请机构数
      specialNotBankWeekOrganizationNumList: null,
      // 特殊时段  夜间 申请次数
      specialNotBankNightNumList: null,
      // 特殊时段  夜间 申请机构数
      specialNotBankNightOrganizationNum: null,

      // ------- 借贷记录 ---------
      // 单项数据
      loanOverallSituation: {},
      // table表格数据
      // 贷款机构
      loanOrganizationList: null,
      // 成功还款金额
      repaymentSuccessMoneyLis: null,
      // 成功还款笔数
      repaymentSuccessNumList: null,
      // 失败交易总金额
      transactionFailMoneyList: null,
      // 失败交易笔数
      transactionFailNumList: null,

      // ----------逾期记录 ----------
      // 失联欺诈/逾期行为
      bankLoss: null,
      // 网贷欺诈失联行为：高风险命中和最后时间
      lost: null,
      lostTime: null,
      // 中风险命中和最后时间
      bad: null,
      badTime: null,
      // 低风险命中和最后时间
      overdue: null,
      overdueTime: null,

      // 不良行为 分数
      grade: 0,

      // 关联人异常检测
      relevanceAbnormalCheck: {},
      cpwsShow: false,
      cpws: [],
      // ------失信案件扫描------
      shixinShow: false,
      shixin: [],
      // ------执行案件扫描 ------
      zxggShow: false,
      zxgg: [],
      // ------限高消费信息 ------
      bgtShow: false,
      bgt: [],
      repaymentSuccessMoneyList: '',
      // 报告客服二维码
      reportImg: '',
      isShowReport: false,
      cid: this.$route.query.cid ? this.$route.query.cid : '',
    };
  },
  created() {
    // console.log(handelSj('贷款机构数', '1', 0));
    Toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    setTimeout(() => {
      this.getReport(this.$route.query.orderNo);
    }, 1000);
    this.report();
  },
  // mounted() {
  //   window.addEventListener('scroll', this.showIcon);
  // },
  // watch: {
  //   isShowReport(val, newval) {
  //     console.log(val, newval, '//newval');
  //   },
  // },
  methods: {
    handelDkSj(type, time, status) {
      return handelSj(type, time, status);
    },
    // showIcon(e) {
    //   let top = e.srcElement.scrollingElement.scrollTop; // 获取页面滚动高度
    //   // console.log(top, '///');
    //   if (top && top > 3000) {
    //     console.log('页面高度大于3000执行操作');
    //     this.isShowReport = true;
    //     // this.$refs.threeItem.report();
    //   } else {
    //     this.isShowReport = false;
    //     console.log('页面高度小于3000执行操作');
    //     // this.$refs.threeItem.cancleReport();
    //   }
    // },
    getReport(url) {
      reference({ orderNo: url })
        .then((res) => {
          if (res.data.code == 200) {
            let result = res.data.data;
            // ---------非银行贷款申请记录 ---------------

            // 借贷记录
            const loanRecordData = result.loanRecordData;

            console.log(loanRecordData);
            //----------裁判文书----------
            this.cpws = result.detail.cpws == null ? false : result.detail.cpws;
            // ------失信案件扫描------
            this.shixin =
              result.detail.shixin == null ? false : result.detail.shixin;
            // ------执行案件扫描 ------
            this.zxgg = result.detail.zxgg == null ? false : result.detail.zxgg;
            // ------限高消费信息 ------
            this.bgt = result.detail.bgt == null ? false : result.detail.bgt;
            // 单项数据
            this.loanOverallSituation = loanRecordData.loanOverallSituation;
            console.log(this.loanOverallSituation);
            // 分析指数
            this.echartsValue = {
              numberColor: '#c3d6ff',
              // 刻度盘
              scaleColor: '#c3d6ff',
              // 分刻度
              scaleDivisionColor: '#9dddcd',
              // 箭头和指针
              arrowsColor: '#5687fd',
              // 最后分值
              valueNumer:
                result.creditScore && result.creditScore != 0
                  ? result.creditScore
                  : this.handelMark(),
            };
            // 报告概况
            this.createTime = result.createTime;
            this.failureTime = result.failureTime;

            // 基本信息
            this.userInfo = result.userInfo;

            // ------------银行贷款类申请记录  --------------
            const bank = result.muchHeadData.applySumList;
            this.columnar = {
              xAxis: ['1天', '7天', '14天', '1个月', '3个月', '6个月'],
              xAxisData1: bank.muchHeadApplyIdCardNumList,
              xAxisData2: bank.muchHeadApplyOrganizationIdCardNumList,
              xAxisData1Color: '#80acfe',
              xAxisData2Color: '#ffb86d',
            };
            // 银行产品

            const verifySumList = result.muchHeadData.verifySumList;

            this.traditionalBankNumList =
              verifySumList.muchHeadApplyVerifyProductIdCardNumList;
            //  传统银行申请机构数
            this.traditionalBankOrganizationNumList =
              verifySumList.muchHeadApplyVerifyProductOrganizationIdCardNumList;

            // 网络零售银行 传统银行次数
            this.netBankNumList =
              verifySumList.muchHeadApplyNotVerifyProductIdCardNumList;
            // 网络零售银行 申请机构数
            this.netBankOrganizationNumList =
              verifySumList.muchHeadApplyNotVerifyProductOrganizationIdCardNumList;
            // 特殊时段申请
            // 周末 申请次数
            this.specialBankWeekNumList = bank.specialBankWeekNumList;
            // 周末 申请机构数
            this.specialBankWeekOrganizationNumList =
              bank.specialBankWeekOrganizationNumList;
            // 夜间 申请次数
            this.specialBankNightNumList = bank.specialBankNightNumList;
            // 夜间 申请机构数
            this.specialBankNightOrganizationNumList =
              bank.specialBankNightOrganizationNumList;

            // table表格数据
            const loanCycleList = result.loanRecordData.loanCycleList;
            console.log(result.loanRecordData.loanCycleList);
            // 贷款机构
            this.loanOrganizationList = loanCycleList.loanOrganizationList;

            // 成功还款金额
            this.repaymentSuccessMoneyList =
              loanCycleList.repaymentSuccessMoneyList;

            // 成功还款笔数
            this.repaymentSuccessNumList =
              loanCycleList.repaymentSuccessNumList;
            // 失败交易总金额
            this.transactionFailMoneyList =
              loanCycleList.transactionFailMoneyList;
            // 失败交易笔数
            this.transactionFailNumList = loanCycleList.transactionFailNumList;
            // 关联人异常检测
            this.relevanceAbnormalCheck = result.relevanceAbnormalCheck;
            //获取订单cid
            this.cid = result.cid; //增加cid为928的判断(百度审核)
            console.log(this.cid, '///1111111111111');
            Toast.clear();
            setTimeout(() => {
              if (this.cid != 928) {
                this.$refs.threeItem.report();
              }
            }, 4000);

            // ----------逾期记录 ----------
            // const overdueRecordDataList = result.overdueRecordDataList;
            // // 失恋欺诈/逾期行为
            // this.bankLoss = overdueRecordDataList.bankLoss;
            // // 网贷欺诈失联行为 : 汽车金融
            // this.carLoanList = overdueRecordDataList.carLoanList;
            // // 消费金融
            // this.connsLoanList = overdueRecordDataList.connsLoanList;
            // //  融资租赁
            // this.finleaLoanList = overdueRecordDataList.finleaLoanList;
            // // 网络小贷
            // this.netLoanList = overdueRecordDataList.netLoanList;
            // // 其他公司
            // this.otherLoanList = overdueRecordDataList.otherLoanList;

            // // 不良记录 分数
            // this.grade = result.analysisIndex;
          } else {
            Toast(res.data.msg);
            setTimeout(() => {
              if (this.cid != 928) {
                this.$refs.threeItem.report();
              }
            }, 5000);
            // console.log(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handelMark() {
      //报告指数分数规则
      let valueNumer = 0;
      if (this.bgt || this.cpws || this.shixin || this.zxgg) {
        valueNumer = 10;
      } else if (
        this.loanOverallSituation.xypCpl0071 != 0 ||
        this.loanOverallSituation.xypCpl0072 != 0
      ) {
        valueNumer = 20;
      } else {
        valueNumer =
          this.loanOverallSituation.xypCpl0001 == 0
            ? 90
            : this.loanOverallSituation.xypCpl0001 == 1
            ? 80
            : this.loanOverallSituation.xypCpl0001 == 2
            ? 60
            : this.loanOverallSituation.xypCpl0001 == 3
            ? 40
            : 100;
      }
      return valueNumer;
    },
    // 机构类别申请记录
    changeTbjgid(id) {
      this.tbjgid = id;
    },
    // 特殊时段申请记录
    changeSpecial(id) {
      this.Specialid = id;
    },
    // 非银行 机构类别申请记录
    changNotBank(id) {
      this.notBankId = id;
    },
    changeWeek(id) {
      this.speciaID = id;
    },
    glrIDbtn1() {
      this.glrID = 1;
      alert(this.glrID);
    },
    glrIDbtn2() {
      this.glrID = 2;
      alert(this.glrID);
    },
    download() {
      // 生成报告过渡作用
      let toast2 = Toast.loading({
        duration: 0,
        message: '生成报告中...',
        forbidClick: true,
      });
      html2canvas(this.$refs.imageTofile, {
        // 设置清晰度
        scale: 1,
        useCORS: true,
      }).then((canvas) => {
        // 清除toast2的弹窗
        toast2.clear();
        // 生成base64图片编码
        let dataURL = canvas.toDataURL('image/png');
        // 打开遮罩层预览图片
        this.show = true;
        this.imgUrl = dataURL;
      });
    },
    downloadImage(url) {
      // 如果是在网页中可以直接创建一个 a 标签直接下载
      let a = document.createElement('a');
      a.href = url;
      a.download = '报告';
      a.click();
    },
    // 下载报告
    downloadImgUrl(img) {
      var u = navigator.userAgent,
        app = navigator.appVersion;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
      if (isAndroid) {
        this.downloadImage(img);
      } else {
        Toast('长按保存图片');
      }
    },
    report() {
      server({ type: 3 })
        .then((res) => {
          if (res.data.code == 200) {
            this.reportImg = res.data.data;
          } else {
            Toast(res.data.msg);
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.success {
  width: 100%;
  min-height: 100vh;
  .dw {
    width: 120px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 22px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    position: fixed;
    top: 120px;
    // #ifdef  APP-PLUS
    top: 60px;
    // #endif
    right: 24px;
    z-index: 2;
    border-radius: 15px;
  }
  .bannerBox {
    position: relative;
    width: 100%;
    min-height: 100px;
    font-size: 0;
    background: #fff;
    img {
      width: 100%;
      z-index: 1;
    }
    &-title {
      color: #2877ff;
      position: absolute;
      font-size: 26px;
      top: 70px;
      left: 60px;
      z-index: 2;
      font-weight: 600;
    }
    .dw_img {
      width: 160px;
      min-height: 160px;
      // background: red;
      position: absolute;
      top: 120px;
      left: 80px;
      border-radius: 20px;
      padding: 20px;
      background: #fff;
    }
  }
  // 分析指数
  #fxzsBox {
    width: 100%;
    min-height: 600px;
    padding-bottom: 24px;
    background: #d8e6ff;
  }
  // 报告概况
  #bggkBox {
    width: 100%;
    min-height: 600px;
    // background: red;
    background: #d8e6ff;
    text-align: center;
    box-sizing: border-box;
    color: #595959;
    font-size: 26px;
    padding-bottom: 24px;
    .generatedTime {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      line-height: 60px;
    }
    .involve {
      height: 200px;
      background: #ecf3ff;
      margin-top: 20px;
      border-radius: 20px;
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      .item {
        display: flex;
        align-items: center;
        color: #595959;
        img {
          margin-right: 15px;
          width: 50px;
          height: 50px;
        }
      }
    }
  }
  // 基本信息
  #jbxxBox {
    background: #d8e6ff;
    padding-bottom: 24px;
    color: #595959;
    .userdata {
      width: 100%;
      height: 140px;
      background: #ecf3ff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .item {
        display: flex;
        justify-content: space-around;
        span {
          color: #595959;
          font-size: 28px;
          width: 100px;
          text-align: center;
        }
      }
    }
    .info {
      width: 100%;
      height: 80px;
      line-height: 80px;
      // background: red;
      font-size: 30px;
      display: flex;
      justify-content: space-between;
      color: #8c8c8c;
      span:nth-child(2) {
        text-align: right;
        color: #595959;
      }
    }
  }
  // 高风险排查
  #gfxBox {
    width: 100%;
    min-height: 400px;
    padding-bottom: 24px;
    background: #d8e6ff;
  }
  .gfxpcItem {
    height: 80px;
    line-height: 80px;
    background: #f5f5f5;
    border-radius: 20px;
    margin-bottom: 10px;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
  }
  // 银行贷款类申请记录
  #yhdkBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .titlebox {
      width: 100%;
      min-height: 550px;
      padding-top: 50px;
      &-content {
        height: 100px;
        line-height: 100px;
        background: #d2e0ff;
        display: flex;
        border-radius: 10px;
        span {
          width: 33%;
          text-align: center;
          color: #595959;
        }
      }
      &-content2 {
        background: #fff;
      }
      &-content3 {
        background: #ecf3ff;
      }
    }
  }
  // 非银贷款
  #fyhdBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .titlebox {
      width: 100%;
      min-height: 550px;
      padding-top: 50px;
      &-content {
        height: 100px;
        line-height: 100px;
        background: #d2e0ff;
        display: flex;
        border-radius: 10px;
        span {
          width: 33%;
          text-align: center;
          color: #595959;
        }
      }
      &-content2 {
        background: #fff;
      }
      &-content3 {
        background: #ecf3ff;
      }
    }
  }
  // 逾期记录
  #yqjlBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .titlebox {
      width: 100%;
      min-height: 550px;
      padding-top: 50px;
      &-content {
        height: 40px;
        padding: 20px 0;
        // line-height: 100px;
        background: #d2e0ff;
        display: flex;
        border-radius: 10px;
        span {
          width: 33%;
          text-align: center;
          color: #595959;
        }
      }
      &-content2 {
        background: #fff;
      }
      &-content3 {
        background: #ecf3ff;
      }
    }
  }
  // 借贷记录
  #jdjlBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .dkTabsBox {
      overflow: hidden;
      overflow-x: auto;
    }
    .dkTabs {
      width: 1500px;
      min-height: 500px;
      overflow-x: auto;
      .dkTabsItem {
        height: 88px;
        line-height: 88px;
        display: flex;
        justify-content: space-around;
        span {
          width: 500px;
          text-align: center;
        }
      }
    }
  }
  .sjbq {
    height: 88px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .active {
    background: #ecf3ff;
  }
  .Antline {
    border-bottom: 1px dashed #5687fd;
    padding-bottom: 20px;
  }

  // 不良记录
  #bljlBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .bljlImg {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 200px;
      }
      .bljlImgBox {
        width: 200px;
        height: 600px;
        // background: red;
        img {
          width: 100px;
        }
      }
    }
  }
  // 关联人异常
  #glryBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .shjbTbas {
      width: 100%;
      height: 80px;
      line-height: 80px;
      background: #fff;
      border-radius: 15px;
      margin-bottom: 20px;
      border: 1px solid #d0e1ff;
      .aaa {
        width: 50%;
        height: 80px;
        // background: #fff;
        float: left;
        text-align: center;
      }
      .bbb {
        width: 50%;
        height: 80px;
        // background: red;
        float: left;
        text-align: center;
      }
      .abActive {
        color: #498afe;
        background: #d0e1ff;
      }
    }
    .sjbq {
      height: 88px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
    .active {
      background: #ecf3ff;
    }
    .Antline {
      border-bottom: 1px dashed #5687fd;
      padding-bottom: 20px;
    }
  }
  // 法院案件
  #fyajBox {
    background: #d8e6ff;
    padding-bottom: 30px;
  }
  // 失信案件扫描
  #sxajBox {
    background: #d8e6ff;
    padding-bottom: 30px;
  }
  // 执行案件扫描
  #axajBox {
    background: #d8e6ff;
    padding-bottom: 30px;
  }
  // 限制高消费信息
  #xzgxBox {
    background: #d8e6ff;
    padding-bottom: 30px;
  }
  // 社交标签
  #sjbqBox {
    background: #d8e6ff;
    padding-bottom: 30px;
    .sjbq {
      height: 88px;
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
    }
    .active {
      background: #ecf3ff;
    }
    .Antline {
      border-bottom: 1px dashed #5687fd;
      padding-bottom: 20px;
    }
  }
  // floot
  .floot {
    // width: 100%;
    min-height: 100px;
    background: #d8e6ff;
    padding: 48px;
    color: #666;
    overflow: hidden;
  }
}
// 公共用的imgBox
.imgBox {
  width: 100%;
  min-height: 136px;
  // padding: 50px 0;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  img {
    width: 100%;
    height: 136px;
  }
  span {
    // margin-top: -88px;
    font-size: 32px;
    // z-index: 1;
    color: #fff;
    position: absolute;
    top: calc(50% - 20px);
  }
}
.content {
  width: calc(100% - 96px);
  min-height: 200px;
  background: #fff;
  margin: 0 auto;
  border-radius: 25px;
  padding: 36px;
  box-sizing: border-box;
  color: #595959;
  span {
    font-size: 28px;
  }
  #main {
    width: 100%;
    height: 400px;
    margin-top: 20px;
  }
}
.unfold {
  width: calc(100% - 96px);
  min-height: 300px;
  background: #fff;
  margin: 0 auto;
  border-radius: 25px;
  margin-top: 20px;
  padding: 36px;
  box-sizing: border-box;
  position: relative;
  &-tips {
    display: flex;
    align-items: center;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    &-one {
      img {
        width: 40px;
      }
    }
    &-two {
      img {
        width: 40px;
      }
    }

    span {
      font-size: 36px;
    }
  }
  &-box {
    height: 50px;
    font-size: 28px;
    line-height: 50px;
    margin-top: 30px;
    font-family: 仿宋;
    overflow: hidden;
  }
  &-boxActive {
    height: auto;
    font-size: 28px;
    line-height: 50px;
    margin-top: 30px;
    font-family: 仿宋;
  }
  .dwbs1 {
    width: 20px;
    position: absolute;
    top: -45px;
  }
  .dwbs2 {
    width: 20px;
    position: absolute;
    top: -45px;
    right: 36px;
  }
}
.mytabs {
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  display: flex;
  &-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .itemTop {
      text-align: center;
      font-size: 26px;
    }
    .itemButton {
      width: 50px;
      height: 5px;
      background: #fff;
    }
    .active1 {
      color: #d2e0ff;
    }
    .active2 {
      width: 60px;
      height: 10px;
      background: #5687fd;
      border-radius: 20px;
    }
  }
}
.titlebg {
  width: 100%;
  min-height: 68px;
  position: relative;
  margin-bottom: 20px;
  img {
    width: 30%;
    height: 70px;
  }
  @media screen and (max-width: 480px) {
    img {
      width: 100%;
      height: 70px;
    }
  }

  .title {
    width: 100%;
    font-size: 30px;
    color: #fff;
    position: absolute;
    top: calc(50% - 30px);
    left: 5%;
  }
}
.falsekong {
  width: calc(100% - 60px);
  min-height: 200px;
  background: red;
  margin: 0 auto;
  border-radius: 10px;
  background: #ecf3ff;
  border: 1px solid #beede0;
  margin-bottom: 40px;
  padding: 20px;
  overflow: hidden;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  &-item1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    span {
      font-size: 16px;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &-item2 {
    display: flex;
    justify-content: flex-start;
    line-height: 66px;
  }
}
.itemActive {
  width: calc(100% - 60px);
  transition: 1s;
  height: 40px;
  background: red;
  margin: 0 auto;
  border-radius: 10px;
  background: #ecf3ff;
  border: 1px solid #beede0;
  margin-bottom: 40px;
  padding: 20px;
  overflow: hidden;
  transition: all 1s;
  -moz-transition: all 1s;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  &-item1 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    span {
      font-size: 16px;
    }
    img {
      width: 40px;
      height: 40px;
    }
  }
  &-item2 {
    display: flex;
    justify-content: flex-start;
    line-height: 66px;
  }
}
.kong {
  text-align: center;
  img {
    width: 300px;
    // width: 50%;
  }
  font-size: 30px;
  padding: 30px 0;
}
.dwthree {
  position: fixed;
  right: 0;
  top: 40%;
}
.previewImg {
  width: 375px;
  height: 1000px;
  // background: red;
  border-radius: 20px;
  overflow: scroll;
  img {
    width: 100%;
  }
}
.reportImg {
  width: 100%;
  // height: 100%;
}
.footer {
  background: #d8e6ff;
  color: #fff;
  text-align: center;
  font-size: 12px;
}
</style>
