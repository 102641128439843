/*
 * @Author: 申馨
 * @Date: 2023-08-28 10:12:30
 * @LastEditors: 申馨
 * @LastEditTime: 2023-08-31 15:56:19
 * @Description: file content
 * @FilePath: \hongan-h5\src\utils\LoanCycle.js
 */
/******贷款分周期汇总 数据处理 */
const data = [
  {
    type: '贷款机构数', //类型
    listArry: [
      {
        time: 1,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1]',
          },
          {
            status: '2',
            amountRange: '[1,3]',
          },
          {
            status: '3',
            amountRange: '[3,更多]',
          },
        ],
      },
      {
        time: 7,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1]',
          },
          {
            status: '2',
            amountRange: '[1,9]',
          },
          {
            status: '3',
            amountRange: '[9,更多]',
          },
        ],
      },
      {
        time: 14,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1]',
          },
          {
            status: '2',
            amountRange: '[1,3]',
          },
          {
            status: '3',
            amountRange: '[3,10]',
          },
          {
            status: '4',
            amountRange: '[10, 更多]',
          },
        ],
      },
      {
        time: 21,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,6]',
          },
          {
            status: '3',
            amountRange: '[6,11]',
          },
          {
            status: '4',
            amountRange: '[11, 更多]',
          },
        ],
      },
      {
        time: 30,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1]',
          },
          {
            status: '2',
            amountRange: '[1,4]',
          },
          {
            status: '3',
            amountRange: '[4,11]',
          },
          {
            status: '4',
            amountRange: '[11, 更多]',
          },
        ],
      },
      {
        time: 90,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,5]',
          },
          {
            status: '2',
            amountRange: '[5,10]',
          },
          {
            status: '3',
            amountRange: '[10,17]',
          },
        ],
      },
      {
        time: 180,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,4]',
          },
          {
            status: '2',
            amountRange: '[4,7]',
          },
          {
            status: '3',
            amountRange: '[7,13]',
          },
          {
            status: '4',
            amountRange: '[13, 更多]',
          },
        ],
      },
    ],
  },
  {
    type: '还款成功笔数', //类型
    listArry: [
      {
        time: 1,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3, 更多]',
          },
        ],
      },
      {
        time: 7,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,13]',
          },
          {
            status: '3',
            amountRange: '[13, 更多]',
          },
        ],
      },
      {
        time: 14,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0-5]',
          },
          {
            status: '2',
            amountRange: '[5,21]',
          },
          {
            status: '3',
            amountRange: '[21, 更多]',
          },
        ],
      },
      {
        time: 21,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,5]',
          },
          {
            status: '2',
            amountRange: '[5,27]',
          },
          {
            status: '3',
            amountRange: '[27, 更多]',
          },
        ],
      },
      {
        time: 30,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,7]',
          },
          {
            status: '2',
            amountRange: '[7,34]',
          },
          {
            status: '3',
            amountRange: '[34, 更多]',
          },
        ],
      },
      {
        time: 90,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,7]',
          },
          {
            status: '2',
            amountRange: '[7,34]',
          },
          {
            status: '3',
            amountRange: '[34, 更多]',
          },
        ],
      },
      {
        time: 180,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,27]',
          },
          {
            status: '3',
            amountRange: '[27,50]',
          },
        ],
      },
    ],
  },
  {
    type: '还款成功总金额', //类型
    listArry: [
      {
        time: 1,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1500]',
          },
          {
            status: '2',
            amountRange: '[1500,5000]',
          },
          {
            status: '3',
            amountRange: '[5000, 更多]',
          },
        ],
      },
      {
        time: 7,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,2000]',
          },
          {
            status: '2',
            amountRange: '[2000,17000]',
          },
          {
            status: '3',
            amountRange: '[17000, 更多]',
          },
        ],
      },
      {
        time: 14,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,9000]',
          },
          {
            status: '2',
            amountRange: '[9000,31000]',
          },
          {
            status: '3',
            amountRange: '[31000, 更多]',
          },
        ],
      },
      {
        time: 21,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1000]',
          },
          {
            status: '2',
            amountRange: '[1000,34000]',
          },
        ],
      },
      {
        time: 30,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,10000]',
          },
          {
            status: '2',
            amountRange: '[10000,36000]',
          },
          {
            status: '3',
            amountRange: '[36000, 更多]',
          },
        ],
      },
      {
        time: 90,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,13000]',
          },
          {
            status: '2',
            amountRange: '[13000,49000]',
          },
          {
            status: '3',
            amountRange: '[49000, 更多]',
          },
        ],
      },
      {
        time: 180,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,2000]',
          },
          {
            status: '2',
            amountRange: '[2000,58000]',
          },
          {
            status: '3',
            amountRange: '[58000, 更多]',
          },
        ],
      },
    ],
  },
  {
    type: '交易失败笔数', //类型
    listArry: [
      {
        time: 1,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,5]',
          },
          {
            status: '3',
            amountRange: '[5,更多]',
          },
        ],
      },
      {
        time: 7,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,5]',
          },
          {
            status: '3',
            amountRange: '[5,7]',
          },
          {
            status: '4',
            amountRange: '[7,更多]',
          },
        ],
      },
      {
        time: 14,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,5]',
          },
          {
            status: '3',
            amountRange: '[5,15]',
          },
          {
            status: '4',
            amountRange: '[15,更多]',
          },
        ],
      },
      {
        time: 21,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,5]',
          },
          {
            status: '3',
            amountRange: '[5,20]',
          },
          {
            status: '4',
            amountRange: '[20,更多]',
          },
        ],
      },
      {
        time: 30,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,5]',
          },
          {
            status: '3',
            amountRange: '[5,34]',
          },
          {
            status: '4',
            amountRange: '[34,更多]',
          },
        ],
      },
      {
        time: 90,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,6]',
          },
          {
            status: '2',
            amountRange: '[6,22]',
          },
          {
            status: '3',
            amountRange: '[22,56]',
          },
          {
            status: '4',
            amountRange: '[56,更多]',
          },
        ],
      },
      {
        time: 180,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,3]',
          },
          {
            status: '2',
            amountRange: '[3,25]',
          },
          {
            status: '3',
            amountRange: '[25,30]',
          },
          {
            status: '4',
            amountRange: '[30,70]',
          },
          {
            status: '5',
            amountRange: '[70,更多]',
          },
        ],
      },
    ],
  },
  {
    type: '交易失败总金额', //类型
    listArry: [
      {
        time: 1,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1500]',
          },
          {
            status: '2',
            amountRange: '[1500,2500]',
          },
          {
            status: '3',
            amountRange: '[2500,5000]',
          },
          {
            status: '4',
            amountRange: '[5000,更多]',
          },
        ],
      },
      {
        time: 7,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,2000]',
          },
          {
            status: '2',
            amountRange: '[2000,10000]',
          },
          {
            status: '3',
            amountRange: '[10000,17000]',
          },
          {
            status: '4',
            amountRange: '[17000,26000]',
          },
          {
            status: '5',
            amountRange: '[26000,更多]',
          },
        ],
      },
      {
        time: 14,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1000]',
          },
          {
            status: '2',
            amountRange: '[1000,4000]',
          },
          {
            status: '3',
            amountRange: '[4000,9000]',
          },
          {
            status: '4',
            amountRange: '[9000,30000]',
          },
          {
            status: '5',
            amountRange: '[30000,更多]',
          },
        ],
      },
      {
        time: 21,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,1000]',
          },
          {
            status: '2',
            amountRange: '[1000,2000]',
          },
          {
            status: '3',
            amountRange: '[2000,30000]',
          },
          {
            status: '4',
            amountRange: '[30000,50000]',
          },
          {
            status: '5',
            amountRange: '[50000,更多]',
          },
        ],
      },
      {
        time: 30,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,6000]',
          },
          {
            status: '2',
            amountRange: '[6000,10000]',
          },
          {
            status: '3',
            amountRange: '[10000,50000]',
          },
          {
            status: '4',
            amountRange: '[50000,更多]',
          },
        ],
      },
      {
        time: 90,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,10000]',
          },
          {
            status: '2',
            amountRange: '[10000,50000]',
          },
          {
            status: '3',
            amountRange: '[50000,80000]',
          },
          {
            status: '4',
            amountRange: '[80000,更多]',
          },
        ],
      },
      {
        time: 180,
        children: [
          {
            status: '0',
            amountRange: '0',
          },
          {
            status: '1',
            amountRange: '[0,2000]',
          },
          {
            status: '2',
            amountRange: '[2000,30000]',
          },
          {
            status: '3',
            amountRange: '[30000,60000]',
          },
          {
            status: '4',
            amountRange: '[60000,90000]',
          },
          {
            status: '5',
            amountRange: '[90000,更多]',
          },
        ],
      },
    ],
  },
];
export function handelSj(type, time, status) {
  let amountRange = '';
  data.forEach((item, index) => {
    if (item.type == type) {
      // console.log(item);
      const obj = item.listArry.filter((item) => item.time == time);
      // console.log(obj[0].children);
      const nextObj = obj[0].children.filter((item) => item.status == status);
      // console.log(nextObj[0]);
      amountRange = nextObj[0] ? nextObj[0].amountRange : '';
    }
  });
  return amountRange;
}
